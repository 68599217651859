<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Penilaian Resiko'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="pabrik_id_label"
                  label="Pabrik :"
                  label-for="pabrik_id"
                >
                  <b-form-select
                    size="sm"
                    id="pabrik"
                    v-model="form.pabrik_id"
                    :options="pabrik"
                    @change="choosePabrik"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="plant_id_label"
                  label="Plant :"
                  label-for="plant_id"
                >
                  <b-form-select
                    id="plant_id"
                    v-model="form.plant_id"
                    :options="plant"
                  ></b-form-select>
                  <!-- <vue-suggestion
                    id="plant_id"
                    :items="plant"
                    v-model="ItemPlant"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangePlant"
                    @selected="choosePlant"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                    size="sm"
                  >
                  </vue-suggestion> -->
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="equipment_id_label"
                  label="Equipment :"
                  label-for="equipment_id"
                >
                  <vue-suggestion
                    id="equipment_id"
                    :items="equipment"
                    v-model="ItemEquipment"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangeEquipment"
                    @selected="chooseEquipment"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                    size="sm"
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tanggal_label"
                  label="Tanggal :"
                  label-for="tanggal"
                >
                  <b-form-input
                    id="tanggal"
                    v-model="$v.form.tanggal.$model"
                    type="date"
                    placeholder="Enter Text..."
                    :state="validateState('tanggal')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="tanggal_feedback">
                    Tanggal Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="probability_label"
                  label="Probability :"
                  label-for="probability"
                >
                  <b-form-select
                    v-model="form.probability"
                    :options="[1, 2, 3, 4, 5]"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="konsekuensi_label"
                  label="Konsekuensi :"
                  label-for="konsekuensi"
                >
                  <b-form-select
                    v-model="form.konsekuensi"
                    :options="[1, 2, 3, 4, 5]"
                  ></b-form-select>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/equipment_kritis')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import $ from 'jquery'

import itemTemplate from '@/view/pages/item-template.vue'

export default {
  mixins: [validationMixin],
  name: 'addequipmentkritis',
  data() {
    return {
      form: {
        equipment_id: null,
        tanggal: null,
        probability: null,
        konsekuensi: null,
      },
      equipment: [],
      pabrik: [],
      plant: [],
      ItemEquipment: null,
      // ItemPlant: null,
      itemTemplate,

      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      show: true,
      loading: false,
    }
  },
  validations: {
    form: {
      tanggal: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Penilaian Resiko', route: '/equipment_kritis' },
      { title: 'Create Penilaian Resiko' },
    ])

    var self = this

    ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }

      var self = this
      self.loading = true
      const equipment_id = self.form.equipment_id
      const tanggal = self.$v.form.tanggal.$model
      const probability = self.form.probability
      const konsekuensi = self.form.konsekuensi

      const dataku = new FormData()
      dataku.append('equipment_id', equipment_id)
      dataku.append('tanggal', tanggal)
      dataku.append('probability', probability)
      dataku.append('konsekuensi', konsekuensi)
      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/master/equipment_kritis/store',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/equipment_kritis')
          }
        },
      })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      var url = '/master/equipment/showequipment/' + text
      if (this.form.pabrik_id && text) {
        url += '/' + this.form.pabrik_id
      }

      if (this.form.plant_id && text) {
        url += '/' + this.form.plant_id
      }

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    inputChangePlant(text) {
      // your search method
      var self = this
      var url = '/master/plant/showplant/' + text
      if (this.form.pabrik_id && text) {
        url += '/' + this.form.pabrik_id
      }

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data

            self.plant.splice(0, self.plant.length)
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                id: plant[i].id_plant,
                name: plant[i].nama_plant,
                pabrik: plant[i].nama_pabrik,
                pabrik_id: plant[i].pabrik_id,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    choosePabrik() {
      var self = this
      ApiService.get(
        '/master/plant/all?page=1&pabrik_id=' +
          self.form.pabrik_id +
          '&sortBy=nama_plant&sortDesc=ASC'
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data.data
            self.plant.splice(0, self.plant.length)
            self.plant.push({
              value: null,
              text: '-- Pilih Plan --',
            })
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                value: plant[i].id_plant,
                text: plant[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id
      this.form.pabrik_id = value.pabrik_id
      this.form.plant_id = value.plant_id
      this.form.pabrik = value.pabrik
      this.form.plant = value.plant

      // this.ItemPlant = {
      //   id: value.plant_id,
      //   name: value.plant,
      //   pabrik: value.pabrik,
      //   pabrik_id: value.pabrik_id,
      // };

      this.choosePabrik()
    },
    // choosePlant: function (value) {
    //   this.form.plant_id = value.id;
    //   this.form.plant = value.nama_plant;
    //   this.form.pabrik_id = value.pabrik_id;
    // },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
